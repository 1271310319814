import { Divider, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { ArrowRight } from "../assests";

interface IProps {
    startTime:string,
    endTime:string,
    name:string,
    hasPicture:boolean,
    url: string
}

const AppointmentCard: FC<IProps> = ({
    startTime = "",
    endTime = "",
    name = "",
    hasPicture = false,
    url = ""
}) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      columnSpacing={2}
      rowSpacing={2}
      style={{marginTop:"30px"}}
    >
      <Grid
        item
        xs={4}
        sm={2}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#979797",
            marginBottom: "10px",
          }}
        >
          {startTime}
        </Typography>
        <Divider
          variant="middle"
          style={{ width: "40%", color: "#D9D9D9", borderBottomWidth: "2px" }}
        />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#979797",
            marginTop: "10px",
          }}
        >
          {endTime}
        </Typography>
      </Grid>

      <Grid
        item
        xs={4}
        sm={6}
        md={9}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          backgroundColor: "#2574FF40",
          borderRadius:5,
          padding:10,
          borderLeft: "4px solid #2574FF",
        }}
      >
        <Grid md={2} style={{alignItems:"center", justifyContent:"center", display:"flex"}}> 
            <img src="https://gravatar.com/avatar/24b73c5456642e2ccee49f0a7ed112bb?s=400&d=robohash&r=x" style={{maxWidth:"64px", maxHeight:"64px", borderRadius:4}} />
        </Grid>
        <Grid md={8} style={{alignItems:"start", justifyContent:"start", display:"flex", flexDirection:"column"}}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#272755",
          }}
        >
          {name}
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "28px",
            color: "rgba(39, 39, 85, 0.75)"
          }}
        >
          Videolu Görüşme
        </Typography>
        </Grid>
        <Grid md={2} style={{display:"flex", justifyContent:"flex-end"}}> 
            <div
             onClick={() => window.open(url, "_blank")}
             style={{width:"58px", height:"58px", backgroundColor:"#FFFFFF", borderRadius:5, alignItems:"center", justifyContent:"center", display:"flex", cursor:"pointer"}}>
                <img src={ArrowRight} style={{width:"40px", height:"40px"}} />
            </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppointmentCard;
